<template>
  <!-- 发短信弹框 -->
  <!-- 发送短信 -->
  <el-dialog
    v-loading="loading"
    :title="$t('vue_label_SMS_send_messages')"
    :visible="dialogTableVisibles"
    :before-close="cancelMssage"
    :width="width"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
  >
    <!-- 标题下面边框线 -->
    <div class="dialog_line"></div>
    <!-- 整体盒子 -->
    <div class="whole_box">
      <!-- 左侧盒子 -->
      <div class="contacts_left">
        <!-- 搜索框 -->
        <!-- <div class="search_box">
          <el-input
            placeholder="搜索潜在客户或联系人..."
            suffix-icon="el-icon-search"
            @focus="contactBox"
          >
          </el-input>
        </div> -->
        <!-- 添加联系人弹框 -->
        <!-- <div v-if="showAddCampaignMembers"> -->
        <addContact
          ref="add"
          :showAddCampaignMembers="showAddCampaignMembers"
          @closeAddCampaignMembersDialog="closeAddCampaignMembersDialog"
        >
        </addContact>
        <!-- </div> -->
        <!-- 收件人内容 -->
        <div class="addressee_box">
          <div class="addressee_title">
            <!--收件人-->
            {{ $t("component_sendmail_label_touser") }} ({{
              contactsData.length
            }})
          </div>
          <div class="recipient_information_box">
            <div
              class="addressee_data"
              v-for="(item, index) in contactsData"
              :key="index"
            >
              <svg class="imgs" aria-hidden="true" v-if="item.type == 'lead'">
                <use href="#icon-contacts"></use>
              </svg>
              <svg class="imgs" aria-hidden="true"  v-if="item.type == 'contact'">
                <use href="#icon-potential"></use>
              </svg>
              <el-tooltip
                effect="dark"
                :content="item.name"
                placement="top-start"
              >
                <div class="contacts_name">{{ item.name }}</div>
              </el-tooltip>

              <div class="contacts_phoneNumber" v-if="item.phone">
                {{ item.phone }}
              </div>
              <div class="contacts_phoneNumber" v-if="item.phone == null">
                <!-- 无电话号码 -->
                <span>{{ $t("vue_label_SMS_nophone") }}</span>
              </div>
              <div class="difference" @click="deleteContact(index)">×</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧盒子 -->
      <div class="news_right_box">
        <!-- 短信文本框 -->
        <div class="text_area">
          <textarea v-model="textarea" id="demoText"> </textarea>
        </div>
        <!-- 模板,表情 -->
        <div class="quick-box">
          <el-popover
            placement="top"
            width="283"
            trigger="click"
            v-model="visible"
            @hide="hidePopver"
            popper-class="template_search_box"
          >
            <!-- 搜索 -->
            <el-input
              :placeholder="$t('label.quickbooks.searchs')"
              prefix-icon="el-icon-search"
              v-model="searchVal"
              @input="popupTemplateSearch"
            >
            </el-input>
            <div class="template_box">
              <!-- 全部模板 -->
              <div class="whole">{{ $t("vue_label_SMS_alltemplates") }}</div>
              <div class="template-style">
                <div
                  v-for="(items, indexs) in templateData"
                  :key="indexs"
                  class="template_data"
                  @click="selectTemplate(items)"
                >
                  {{ items.name }}
                </div>
              </div>
            </div>
            <div class="template-img" slot="reference">
              <svg style="display: inline-block;
  width: 18px;
  height: 18px;" aria-hidden="true">
                <use href="#icon-Template"></use>
              </svg>
            </div>
          </el-popover>
          <div class="expression-img">
            <VueEmojiBox
              targetId="demoText"
              :baseUrl="baseUrl"
              :customEmojis="emojis"
              :customCategories="emojiCategory"
              height="200px"
              @select="selectEmoji"
              label=" "
              :visible="false"
            />
          </div>
        </div>
        <!-- 相关项 -->
        <div class="related_items_box">
          <!--相关项  -->
          <div class="related_style">{{ $t("label.relateobj") }}</div>
          <div class="relaventSelect">
            <relaventSelect></relaventSelect>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <div class="button_style">
        <!-- 取消 -->
        <el-button @click="cancelMssage">{{
          $t("button_partner_cancel")
        }}</el-button>
        <el-dropdown split-button type="primary" @click="confirmationMessage">
          <!-- 发送 -->
          {{ $t("label_chatter_send") }}
          <el-dropdown-menu slot="dropdown">
            <!--分别发送  -->
            <el-dropdown-item @click.native="sendSeparately">{{
              $t("label.emailobject.emailsend.sendindividually")
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getTextMessageSendTextMessage } from "../api.js"; //短信接口
import addContact from "./addContact";
import VueEmojiBox from "vue-emoji-box";
import { emojiCategory, emojis } from "@/assets/emoji";
import relaventSelect from "@/components/Email/relaventSelect";
// import SearchTable from "@/components/Form/search-table.vue";
import {
  getTextMessageGetTextMessageTemplata,
  getTextMessageGetShouJianRenData,
} from "../api.js"; //短信模板

export default {
  components: {
    addContact,
    relaventSelect,
    VueEmojiBox,
  },
  props: {
    // dialogTableVisible: {
    //   type: Boolean,
    //   default: false,
    // },
    width: {
      type: String,
      default: "755px",
    },
  },
  data() {
    return {
      loading: false,
      dialogTableVisibles: false,
      visible: false, //弹框显示隐藏
      // 查找多选
      showSearchTables: false,
      fieldId: "",
      checkeds: false,
      relevantObjId: "",
      relevantPrefix: "",
      // 模板绑定的值
      searchVal: "",
      // 模板搜索关键字
      templateKeywords: "",
      // 表情包
      emojiCategory,
      emojis,
      baseUrl: "../../assets/emoji.js",
      // 添加联系人弹框
      showAddCampaignMembers: false,
      // 右侧联系人数据
      contactsData: [],
      // 去重数组
      duplicateRemovalData: [],
      // 模板数据
      templateData: [],
      value: "",
      // 头像显示判断
      customerPortrait: false,
      headPortrait: false,
      // 编辑消息文本
      textarea: "",
      typeData: "",
      // 相关项id
      relateid: "",
      // 搜索传参
      searchStatus: false,
    };
  },
  beforeDestroy(){
    this.$Bus.$off("deliver-sms-data", this.deliverSmsDataCallback);
    this.$Bus.$off("deliver-relateid", this.deliverRelatedIdCallback);
  },
  mounted() {
    this.SmsTemplateRequest();
    this.$Bus.$on("deliver-sms-data", this.deliverSmsDataCallback);
    this.$Bus.$on("deliver-relateid", this.deliverRelatedIdCallback);
  },
  methods: {
    deliverSmsDataCallback(res, val){
      this.dialogTableVisibles = true;
      let ids = res.map((res) => res.id);
      let name = res.map((res) => res.name).join();
      let option = {
        ids: ids,
        name: name,
        type: val,
      };
      this.getRecipientInformation(option);
    },
    deliverRelatedIdCallback(res){
      this.relateid = res;
    },
    // 文本框输入时，失去焦点和光标时，记录光标的位置，并且往最后一次光标的位置添加表情包
    // eslint-disable-next-line no-unused-vars
    selectEmoji(emoji) {
      // var elInput = document.getElementById('demoText') //根据id选择器选中对象
      // var startPos = elInput.selectionStart // input 第0个字符到选中的字符
      // var endPos = elInput.selectionEnd // 选中的字符到最后的字符
      // if (startPos === undefined || endPos === undefined) return
      // var txt = elInput.value
      // // 将表情添加到选中的光标位置
      // var result =
      //   txt.substring(0, startPos) + emoji.emoji + txt.substring(endPos)
      // elInput.value = result // 赋值给input的value
      // // 重新定义光标位置
      // elInput.focus()
      // 
      // elInput.selectionStart = startPos + emoji.emoji.length
      // elInput.selectionEnd = startPos + emoji.emoji.length
      // this.textarea = result // 赋值给表单中的的字段
    },

    // 监听模板弹框隐藏时的方法
    hidePopver() {
      // 处理
      this.searchVal = "";
      this.SmsTemplateRequest();
    },
    //短信模板数据请求
    async SmsTemplateRequest() {
      let params = {
        onlyShowMyTemplate: this.searchStatus,
        searchWord: "",
      };
      let res = await getTextMessageGetTextMessageTemplata(params);
      this.templateData = res.data;
    },
    // 弹框模板搜索
    async popupTemplateSearch(val) {
      this.templateKeywords = val;
      let Template = {
        onlyShowMyTemplate: this.searchStatus,
        searchWord: this.templateKeywords,
      };
      let res = await getTextMessageGetTextMessageTemplata(Template);
      this.templateData = res.data;
    },
    // 选中的模板
    selectTemplate(items) {
      if (this.textarea == "") {
        this.textarea += items.mainbody;
        this.visible = false;
      } else if (this.textarea !== "") {
        this.textarea = "";
        this.textarea += items.mainbody;
        this.visible = false;
      }
    },
    // 群发
    confirmationMessage() {
      this.loading = true;
      this.textarea = document.getElementById("demoText").value;
      let ids = this.contactsData.map((res) => res.id).join();
      let parameter = {
        ids: ids,
        relateid: this.relateid,
        content: this.textarea,
        type: "1",
      };
      this.sendSMSRequest(parameter);
    },
    // 分别发送
    sendSeparately() {
      this.loading = true;
      this.textarea = document.getElementById("demoText").value;
      let ids = this.contactsData.map((res) => res.id).join();
      let parameter = {
        ids: ids,
        relateid: this.relateid,
        content: this.textarea,
        type: "0",
      };
      this.sendSMSRequest(parameter);
    },
    // 发短信请求
    async sendSMSRequest(val) {
      let Sms = await getTextMessageSendTextMessage(val); //发短信接口
      this.loading = false;
      if (Sms.data.sendstatus == "0") {
        this.textarea = "";
        this.dialogTableVisibles = false;
        this.$message({
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: this.$i18n.t("vue_label_SMS_notice_send_sussess"), //短信已发送
        });
      } else if (Sms.data.sendstatus !== "0") {
        this.textarea = "";
        this.dialogTableVisibles = false;
        this.$message({
          showClose: true,
          message: this.$i18n.t("vue_label_SMS_notice_send_sussess"), //短信已发送
          type: "success",
        });
      }
    },
    // 取消
    cancelMssage() {
      this.contactsData = []
      this.dialogTableVisibles = false;
    },
    // 取消
    closeAddCampaignMembersDialog() {
      this.showAddCampaignMembers = false;
    },

    // 下一步数据请求回显,获取收件人信息：option：点击下一步带过来的选中的人的集合
    async getRecipientInformation(option) {
      // 判断此次选择的人员中在之前是否已经选择过，若是选择过，则直接删除已选择过的人员的id即可。
      // this.contactsData.map((item) => {
      //   // 判断是否包含已选择过的人员
      //   if (option.ids.some((item1) => item1 === item.id)) {
      //     // 若是包含，查找匹配的index，删除
      //     let index = option.ids.findIndex((item2) => item2 === item.id);
      //     option.ids.splice(index, 1);
      //   }
      // });
      let idsTmp = JSON.parse(JSON.stringify(option.ids)).join();
      // 请求接口，获取选择的所有人的名称及手机号
      let addressee = await getTextMessageGetShouJianRenData({
        ids: idsTmp,
        type: option.type,
      });
      let arr = [];
      arr = addressee.data.map((item, index) => {
        item.id = option.ids[index];
        item.type = option.type;
        return item;
      });
      this.contactsData.push(...arr);
      let obj = {};
      this.contactsData = this.contactsData.reduce((cur, next) => {
        obj[next.id] ? "" : (obj[next.id] = true && cur.push(next));
        return cur;
      }, []);
    },
    // 删除选中联系人
    deleteContact(index) {
      this.contactsData.splice(index, 1);
    },
    // 选中表情包事件
    // eslint-disable-next-line no-unused-vars
    bindChange(item) {
      // this.textarea += item.text
      // document.getElementById('demoText').focus()
      // renewHtml
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-button-group .el-button--primary:last-child {
  height: 30px;
}
::v-deep .el-dropdown {
  padding-left: 10px;
}
.dialog_line {
  border-bottom: 1px solid #dddbda;
}
.whole_box {
  display: flex;
}
// 左侧
.contacts_left {
  width: 292px;
  height: 419px;
  border: 1px solid #d8d8d8;
  border-top: none;
  border-bottom: none;
  border-left: none;
  .search_box {
    width: 246px;
    height: 50px;
    padding: 11px 8px 0 0px;
    margin-left: 36px;
    ::v-deep .el-input--suffix .el-input__inner {
      height: 28px;
    }
    ::v-deep .el-input__icon {
      line-height: 30px;
    }
  }
  .addressee_box {
    width: 255px;
    height: 355px;
    margin-left: 36px;
    .addressee_title {
      color: #333333;
      padding: 3px 0 0 5px;
    }
    .recipient_information_box {
      width: 100%;
      height: 322px;
      overflow: auto;
    }
    .addressee_data {
      width: 100%;
      height: 40px;
      display: flex;
      margin-top: 10px;
      position: relative;
      background: #f3f2f2;
      border-radius: 3px;
      .imgs {
        display: inline-block;
        padding-left: 10px;
        padding-top: 9px;
        width: 30px;
        height: 30px;
      }
      .contacts_name {
        width: 85px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 9px 10px 15px 8px;
        color: #006dcc;
      }
      .contacts_phoneNumber {
        padding-top: 9px;
        span {
          color: #cc0000;
        }
      }
      .difference {
        position: absolute;
        right: 9px;
        top: 4px;
        cursor: pointer;
        font-size: 20px;
      }
    }
  }
}
// 右侧
.news_right_box {
  width: 520px;
  height: 419px;
  .text_area {
    padding: 12px 27px 0 9px;
    textarea {
      width: 420px;
      height: 274px;
      border-color: #dddbda;
    }
  }
  //  模板，表情
  .quick-box {
    width: 100%;
    height: 30px;
    display: flex;
    .template-img {
      margin: 5px 0 0 10px;
      cursor: pointer;
    }
    .expression-img {
      margin: 7px 0 0 8px;
      cursor: pointer;
      ::v-deep .vemoji-main--target {
        span {
          display: inline-block;
          width: 15px;
          height: 15px;
          background: url("../../../assets/noteSms/expression.svg") no-repeat
            center center;
          background-size: 15px 15px;
          margin-top: 2.5px;
        }
      }
    }
    ::v-deep .vemoji-main--content {
      position: absolute;
      margin-top: -258px;
    }
    .mian_box {
      width: 100%;
    }
    .button-box {
      display: flex;
      justify-content: flex-end;
    }
  }
} // 框内样式
.template_box {
  width: 243px;
  height: 183px;
  border: 1px solid #d8d8d8;
  margin: 20px 0 0 6px;
  .whole {
    padding: 10px 0 0 10px;
  }
  .template-style {
    width: 210px;
    height: 77%;
    overflow: auto;
    margin-top: 10px;
    margin-left: 29px;
    padding: 0px 22px 0 0px;
    .template_data {
      height: 27%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }
}
.related_items_box {
  width: 100%;
  margin-top: 8px;
  height: 61px;
  .related_style {
    padding-left: 10px;
  }
  .event-item {
    width: 100%;
    height: 45px;
    padding-left: 10px;
    ::v-deep .el-input__inner {
      height: 30px;
    }
  }
}

.button_style {
  padding: 18px 23px 0 0px;
}
// 去掉弹框内边距
::v-deep .el-dialog__footer {
  padding: 0px !important;
}
::v-deep .dialog-footer {
  height: 66px;
}
// 去掉内部边框
::v-deep .el-dialog__body {
  padding: 0px !important;
}
::v-deep .el-dialog {
  width: 690px;
}
</style>
